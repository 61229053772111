import React, { useRef, useCallback, useState, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonAlert,
  IonToast,
} from "@ionic/react";
import { Layer, MapRef, Marker, Popup, Source } from "react-map-gl";
import { SearchBusiness } from "./SearchBusiness";
import RestaurantDetailsModal from "./RestaurantDetailsModal";
import { supabase } from "../supabaseClient";
import UserBusinessesDrawer from "./UserBusinessDrawer/UserBusinessDrawer";
import { LinearBlur } from "progressive-blur";
import { Geolocation } from '@capacitor/geolocation';
import { debounce } from 'lodash';
import { CirclePaint } from "mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import SuperCluster from 'supercluster';
import MapBox from "./Mapbox/Mapbox";
import StatusBarWrapper, { useStatusBarPadding } from "../utils/useStatusBarPadding";

interface PopupInfo {
  longitude: number;
  latitude: number;
  properties: Record<string, unknown>;
}

interface User {
  id: string;
}

interface Business {
  id: number;
  name: string;
  longitude: number;
  latitude: number;
}

type ClusterProperties = {
  cluster: boolean;
  cluster_id: number;
  point_count: number;
  point_count_abbreviated: string;
  id: number;
  name: string;
  longitude: number;
  latitude: number;
};

const RESTAURANT_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="white">
    <path d="M11 9H9V2H7v7H5V2H3v7c0 2.12 1.66 3.84 3.75 3.97V22h2.5v-9.03C11.34 12.84 13 11.12 13 9V2h-2v7zm5-3v8h2.5v8H21V2c-2.76 0-5 2.24-5 4z"/>
  </svg>
);

const PIN_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none"
  >
    <circle cx="11.7391" cy="11.7391" r="9.5" fill="#0C79FE" stroke="white" strokeWidth="3"/>
  </svg>
);


const CLUSTER_ZOOM_THRESHOLD = 13; // Zoom level to switch between clusters and individual markers

const MapComponent: React.FC = () => {
  const [selectedRestaurantId, setSelectedRestaurantId] = useState<string | null>(null);
  const [userBalance, setUserBalance] = useState(0);
  const [user, setUser] = useState<User | null>(null);
  const [unclaimedRevenue, setUnclaimedRevenue] = useState<number | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [userLocation, setUserLocation] = useState<[number, number] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const prevUnclaimedRevenue = useRef<number | null>(null);
  const [canLoadMap, setCanLoadMap] = useState(true);
  const [showMapLimitToast, setShowMapLimitToast] = useState(false);
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [viewState, setViewState] = useState({
    longitude: -75.6959652,
    latitude: 45.4179255,
    zoom: 15.93,
  });
  const mapRef = useRef<MapRef>(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [reloadData, setReloadData] = useState(false);

  // Function to get the user's current position
  useEffect(() => {
    const getCurrentPosition = async () => {
      try {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setUserLocation([longitude, latitude]); // Store user location in [lng, lat] format
          },
          (error) => {
            //console.error('Error getting location:', error);
          }
        );
      } catch (error) {
        //console.error('Error with geolocation:', error);
      }
    };

    getCurrentPosition();
  }, []);

  // Fly to user location when both the map and location are ready
  useEffect(() => {
    if (isMapLoaded && userLocation && mapRef.current) {
      const map = mapRef.current.getMap();
      map.flyTo({
        center: userLocation, // [longitude, latitude]
        zoom: 15.93,
        essential: true, // This ensures that the animation is essential and cannot be interrupted
      });
    }
  }, [isMapLoaded, userLocation]);

  const updateUserData = async () => {
    if (!user) return;

    const { data, error } = await supabase.rpc('get_user_financials', { user_id: user.id });
    if (error) {
      // eslint-disable-next-line no-console
      //console.error('Error fetching user financials:', error);
    } else if (data && data.length > 0) {
      const financials = data[0];
      // eslint-disable-next-line no-console
      const balance = financials.cash_available || 0;
      const netWorth = financials.net_worth || 0;
      setUserBalance(balance);
    }

    const { data: unclaimedData, error: unclaimedError } = await supabase.rpc('get_unclaimed_revenue', { input_player_id: user.id });
    if (unclaimedError) {
      // eslint-disable-next-line no-console
      //console.error('Error fetching unclaimed revenue:', unclaimedError);
    } else if (unclaimedData !== null) {
      setUnclaimedRevenue(unclaimedData);
      if (unclaimedData > 0 && unclaimedData !== prevUnclaimedRevenue.current) {
        setShowAlert(true);
      }
      prevUnclaimedRevenue.current = unclaimedData;
    }
  };

  useEffect(() => {
    const fetchInitialUserData = async () => {
      setIsLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        setUser(user);
        await updateUserData();
      }
      setIsLoading(false);
    };

    fetchInitialUserData();

    const intervalId = setInterval(updateUserData, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const claimRevenue = async (double = false) => {
    if (user && unclaimedRevenue) {
      const { error } = await supabase.rpc('claim_user_revenue', { user_id: user.id });
      if (error) {
        // eslint-disable-next-line no-console
        //console.error('Error claiming revenue:', error);
      } else {
        const claimedAmount = double ? unclaimedRevenue * 2 : unclaimedRevenue;
        setUserBalance(prevBalance => prevBalance + claimedAmount);
        setUnclaimedRevenue(0);
        setShowAlert(false);
      }
    }
  };

  const watchAd = () => {
    // eslint-disable-next-line no-console
    //console.log("Watching ad...");
    claimRevenue(true);
  };

  useEffect(() => {
    const checkMapLoads = async () => {
      const { data, error } = await supabase.rpc('increment_map_loads');
      if (error) {
        // eslint-disable-next-line no-console
        //console.error('Error checking map loads:', error);
        setCanLoadMap(false);
      } else {
        setCanLoadMap(data);
        if (!data) {
          setShowMapLimitToast(true);
        }
      }
    };

    checkMapLoads();
  }, []);

  const loadBusinesses = useCallback(async () => {
    if (!mapRef.current) return;

    const bounds = mapRef.current.getBounds();
    const { data, error } = await supabase.rpc('businesses_in_view', {
      min_lat: bounds.getSouth(),
      min_long: bounds.getWest(),
      max_lat: bounds.getNorth(),
      max_long: bounds.getEast(),
      zoom_level: Math.floor(viewState.zoom)
    });

    if (error) {
      //console.error('Error loading businesses:', error);
    } else {
      setBusinesses(data);
    }
  }, [viewState.zoom]);

  const handleMarkerClick = useCallback((business: Business) => {
    setSelectedRestaurantId(business.id.toString());
  }, []);

  const onMoveEnd = useCallback(() => {
    const newViewState = mapRef.current?.getMap().getCenter();
    if (newViewState) {
      setViewState(prev => ({
        ...prev,
        longitude: newViewState.lng,
        latitude: newViewState.lat,
        zoom: mapRef.current?.getZoom() || prev.zoom,
      }));
    }
    loadBusinesses();
  }, [loadBusinesses]);

  useEffect(() => {
    loadBusinesses();
  }, [loadBusinesses]); 

  const paddingTop = useStatusBarPadding();

  if (isLoading) {
    return <IonPage><IonContent>Loading...</IonContent></IonPage>;
  }

  return (
    <>
      <IonPage>
        <UserBusinessesDrawer userId={user?.id || null} reloadData={reloadData} setReloadData={setReloadData} />
        <div style={{ paddingTop: paddingTop + 'px' }} className="absolute top-2 left-0 right-0 z-10 p-3 bg-transparent">
          <div className="flex items-center justify-between space-x-1 text-white">
            <div className="flex items-center space-x-1 w-[85%] md:w-[350px] gap-2">
              <div className="w-10 h-10 rounded-full bg-[#262932]/40 p-[9px]" id="open-modal-search-business">
                <svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 21L16.65 16.65M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
                    stroke="currentColor"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        

        <IonContent fullscreen color="light" className="ion-no-padding map-content no-scroll" style={{ height: '100vh', overflow: 'hidden' }}>
          <div style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
            {canLoadMap ? (
              <MapBox 
                onMarkerClick={handleMarkerClick}
                initialViewState={viewState}
                mapRef={mapRef}
                setIsMapLoaded={setIsMapLoaded}
              />
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', backgroundColor: '#f0f0f0' }}>
                <p>Map loading limit reached for this month. Please try again later.</p>
              </div>
            )}
          </div>

          <SearchBusiness setReloadData={setReloadData} reloadData={reloadData} />
          <RestaurantDetailsModal
            isOpen={selectedRestaurantId !== null}
            onClose={() => setSelectedRestaurantId(null)}
            restaurantId={selectedRestaurantId}
            setReloadData={setReloadData}
            reloadData={reloadData}
          />
        </IonContent>

        <div className="absolute top-0 left-0 right-0 z-[9] h-24 pointer-events-none">
          <LinearBlur side="top"
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: '0',
              left: '0',
              right: '0',
              bottom: '0',
            }}
          />
        </div>
      </IonPage>
    
      <IonAlert
        isOpen={showAlert}
        mode="ios"
        onDidDismiss={() => setShowAlert(false)}
        header="Since you've been gone..."
        message={`You have $${unclaimedRevenue?.toFixed(2)} in unclaimed revenue!
                  Your current balance: $${userBalance.toFixed(2)}`}
        buttons={[
          {
            text: 'Claim',
            handler: () => claimRevenue()
          },
          {
            text: 'Double (Watch Ad)',
            handler: watchAd
          }
        ]}
      />
      <IonToast
        isOpen={showMapLimitToast}
        onDidDismiss={() => setShowMapLimitToast(false)}
        message="Map loading limit reached for this month. Some features may be unavailable."
        duration={5000}
        position="top"
      />
    </>
  );
};

export default MapComponent;
