import React, { useState, useEffect, useCallback, useRef } from 'react';
import { IonModal, IonContent, IonList, IonItem, IonLabel, IonNote, IonIcon, IonSpinner, IonButton, IonFab } from '@ionic/react';
import { cashOutline, timeOutline, walletOutline, trendingUpOutline, swapHorizontal } from 'ionicons/icons';
import { supabase } from '../../supabaseClient';
import './UserBusinessDrawer.css';
import StatusBarWrapper, { useStatusBarPadding } from '../../utils/useStatusBarPadding';

interface Business {
  business_id: string;
  business_name: string;
  ownership_percentage: number;
  daily_revenue: number;
}

interface UserFinancials {
  netWorth: number;
  cashAvailable: number;
  assetsValue: number;
}

interface UserBusinessesDrawerProps {
  userId: string | null;
  reloadData: boolean;
  setReloadData: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserBusinessesDrawer: React.FC<UserBusinessesDrawerProps> = ({ userId, reloadData, setReloadData }) => {
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [totalDailyRevenue, setTotalDailyRevenue] = useState(0);
  const [timeUntilNextPayout, setTimeUntilNextPayout] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [financials, setFinancials] = useState<UserFinancials>({ netWorth: 0, cashAvailable: 0, assetsValue: 0 });
  const [view, setView] = useState<'empire' | 'moneyGenerator'>('moneyGenerator');
  const [moneyGenerated, setMoneyGenerated] = useState(0);
  const [clickPower, setClickPower] = useState(1);
  const [pendingClicks, setPendingClicks] = useState(0);
  const [pendingMoney, setPendingMoney] = useState(0);
  const updateTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [realTimeNetWorth, setRealTimeNetWorth] = useState(0);

  const fetchUserStats = async () => {
    if (userId) {
      const { data, error } = await supabase
        .from('user_stats')
        .select('click_power')
        .eq('id', userId)
        .single();
      
      if (error) {
        logError('Error fetching user stats:', error);
      } else if (data) {
        setClickPower(data.click_power);
      }
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserBusinesses();
      fetchUserFinancials();
      fetchUserStats(); // Fetch click_power here
  
      // Optionally reset reloadData after fetching
      if (reloadData) {
        setReloadData(false); // Reset the state so it can be triggered again in the future
      }
    }
  }, [userId, reloadData]);

  useEffect(() => {
    const interval = setInterval(updateTimeUntilNextPayout, 1000);
    return () => clearInterval(interval);
  }, []);

  const logError = (message: string, error: any) => {
    // Implement your preferred error logging method here
    // For example, you could send errors to a monitoring service
  };

  const fetchUserBusinesses = async () => {
    const { data, error } = await supabase.rpc('get_user_businesses_revenue', { user_id: userId });
    if (error) {
      logError('Error fetching user businesses:', error);
    } else if (data) {
      setBusinesses(data);
      const total = data.reduce((sum: number, business: Business) => sum + business.daily_revenue, 0);
      setTotalDailyRevenue(parseFloat(total.toFixed(2)));
    }
  };

  const fetchUserFinancials = async () => {
    const { data, error } = await supabase.rpc('get_user_financials', { user_id: userId });
    if (error) {
      logError('Error fetching user financials:', error);
    } else if (data && data.length > 0) {
      const financialData = data[0];
      const netWorth = parseFloat(financialData.net_worth?.toFixed(2) || '0');
      setFinancials({
        netWorth: netWorth,
        cashAvailable: parseFloat(financialData.cash_available?.toFixed(2) || '0'),
        assetsValue: parseFloat(financialData.assets_value?.toFixed(2) || '0')
      });
      setRealTimeNetWorth(netWorth);
    } else {
      logError('No financial data returned', null);
    }
    setIsLoading(false);
  };

  const updateTimeUntilNextPayout = () => {
    const now = new Date();
    const nextPayout = new Date(now);
    nextPayout.setHours(6, 0, 0, 0);
    if (now > nextPayout) nextPayout.setDate(nextPayout.getDate() + 1);
    
    const diff = nextPayout.getTime() - now.getTime();
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);
    
    setTimeUntilNextPayout(`${hours}h ${minutes}m ${seconds}s`);
  };

  const toggleView = () => {
    setView(view === 'empire' ? 'moneyGenerator' : 'empire');
  };

  const generateMoney = useCallback(() => {
    setPendingClicks(prev => prev + 1);
    const newClickPower = (pendingClicks + 1) % 10 === 0 ? clickPower * 1.1 : clickPower;
    const newMoneyGenerated = newClickPower;

    setPendingMoney(prev => prev + newMoneyGenerated);
    setMoneyGenerated(prev => prev + newMoneyGenerated);
    setRealTimeNetWorth(prev => prev + newMoneyGenerated);

    if ((pendingClicks + 1) % 10 === 0) {
      setClickPower(newClickPower);
    }

    if (updateTimeoutRef.current) {
      clearTimeout(updateTimeoutRef.current);
    }
    updateTimeoutRef.current = setTimeout(updateMoneyInDatabase, 2000);
  }, [pendingClicks, clickPower]);

  const updateMoneyInDatabase = async () => {
    if (pendingMoney > 0 && pendingClicks > 0 && userId) {
      const { error } = await supabase.rpc('update_user_money', {
        amount: pendingMoney,
        clicks: pendingClicks,
        input_user_id: userId
      });

      if (error) {
        logError('Error updating money:', error);
      } else {
        setPendingMoney(0);
        setPendingClicks(0);
        // Update local financial data
        setFinancials(prev => ({
          ...prev,
          netWorth: realTimeNetWorth,
          cashAvailable: prev.cashAvailable + pendingMoney
        }));
      }
    }
  };

  useEffect(() => {
    return () => {
      if (updateTimeoutRef.current) {
        clearTimeout(updateTimeoutRef.current);
      }
      updateMoneyInDatabase();
    };
  }, []);

  const paddingTop = useStatusBarPadding();

  return (
    <>
      <div> 
        <IonFab vertical="top" horizontal="end" slot="fixed" className=" mr-2" style={{ marginTop: `${paddingTop}px`}}>
          <button
          type="button"
          className="rounded-full bg-black px-2.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          ${realTimeNetWorth.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
        </button>
        </IonFab>
      </div>

      <IonModal isOpen={true} canDismiss={false} breakpoints={[0.2, 0.5, 0.75, 1]} initialBreakpoint={0.5} backdropBreakpoint={0.75} backdropDismiss={false} className="business-modal">
        <IonContent className="ion-padding business-modal-content ">
          <div className="custom-business-modal rounded-xl relative ">
            <IonButton
              onClick={toggleView}
              className="absolute top-0 right-4 z-[50] glassmorphism-content"
              fill="solid"
              color="primary"
              size="small"
            >
              <IonIcon icon={swapHorizontal} />
            </IonButton>

            <div className="rounded-xl bg-transparent">
              <div className="glassmorphism-content !rounded-3xl">
                <h1 className="text-2xl font-bold mb-4">{view === 'empire' ? 'Your Empire' : 'Money Generator'}</h1>
                {isLoading ? (
                <div className="flex justify-center items-center h-full">
                    <IonSpinner />
                </div>
                ) : view === 'empire' ? (
                // Empire View
                <>
                    <div className="mb-6 rounded-lg">
                    <p className="text-lg flex items-center mb-2">
                        <IonIcon icon={walletOutline} className="mr-2" />
                        Net Worth: ${realTimeNetWorth.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                    </p>
                    <p className="text-md flex items-center mb-2">
                        <IonIcon icon={cashOutline} className="mr-2" />
                        Cash Available: ${financials.cashAvailable.toLocaleString()}
                    </p>
                    <p className="text-md flex items-center mb-2">
                        <IonIcon icon={trendingUpOutline} className="mr-2" />
                        Assets Value: ${financials.assetsValue.toLocaleString()}
                    </p>
                    </div>
                    
                    <h2 className="text-xl font-semibold mb-2">Your Businesses</h2>
                    <p className="text-lg mb-4">
                    Total Daily Revenue: ${totalDailyRevenue.toFixed(2)}
                    </p>
                    <p className="text-md mb-4 flex items-center">
                    <IonIcon icon={timeOutline} className="mr-2" />
                    Next payout in: {timeUntilNextPayout}
                    </p>
                    <IonList>
                    {businesses.map((business) => (
                        <IonItem key={business.business_id} className="my-2 rounded-lg shadow-sm">
                        <IonLabel>
                            <h2 className="font-semibold">{business.business_name}</h2>
                            <p className="text-sm text-gray-500">
                            Ownership: {business.ownership_percentage.toFixed(2)}%
                            </p>
                        </IonLabel>
                        <IonNote slot="end" className="text-right">
                            <p className="font-medium">
                            ${business.daily_revenue.toFixed(2)}/day
                            </p>
                        </IonNote>
                        </IonItem>
                    ))}
                    </IonList>
                </>
                ) : (
                // Money Generator View
                <div className="flex flex-col items-center justify-center h-full">
                    <p className="text-2xl mb-4">Net Worth 0.0.4: ${realTimeNetWorth.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>
                    <p className="text-xl mb-4">Money Generated: ${moneyGenerated.toFixed(2)}</p>
                    <p className="text-lg mb-2">Click Power: ${clickPower.toFixed(2)}</p>
                    <div
                    onClick={generateMoney}
                    className="flex h-[180px] w-[180px] border border-green-500 rounded-full items-center justify-center text-center text-2xl font-bold cursor-pointer transition-transform active:scale-95"
                    >
                    Tap to Generate Money
                    </div>
                    <p className="text-sm mt-4">Next power increase in {10 - (pendingClicks % 10)} clicks</p>
                </div>
                )}
            </div>
          </div>
        </div>
      </IonContent>
    </IonModal>
    </>
  );
};

export default UserBusinessesDrawer;
