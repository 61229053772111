import React, { useState, useEffect, useRef } from "react";
import { IonModal, IonContent, IonButton, IonRange, IonLabel } from "@ionic/react";
import { supabase } from "../supabaseClient";
import './RestaurantDetailsModal.css';

interface RestaurantDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  restaurantId: string | null;
  setReloadData: (reloadData: boolean) => void;
  reloadData: boolean;
}

interface Owner {
  id: string;
  username: string;
  percentage: number;
}

interface Business {
  id: string;
  name: string;
  category: string;
  business_type: string;
  valuation: number;
}

interface User {
  id: string;
  // Add other user properties as needed
}

const RestaurantDetailsModal: React.FC<RestaurantDetailsModalProps> = ({
  isOpen,
  onClose,
  restaurantId,
  setReloadData,
  reloadData
}) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const [restaurant, setRestaurant] = useState<Business | null>(null);
  const [owners, setOwners] = useState<Owner[]>([]);
  const [sharesToBuy, setSharesToBuy] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  // Remove unused state
  // const [availableShares, setAvailableShares] = useState<number>(100);
  const [userBalance, setUserBalance] = useState<number>(0);

  useEffect(() => {
    if (restaurantId) {
      fetchRestaurantDetails();
      fetchOwners();
    }
    fetchCurrentUser();
  }, [restaurantId]);

  // Replace console.error with a custom error logging function
  const logError = (message: string, error: any) => {
    // Implement your preferred error logging method here
    // For example, you could send errors to a monitoring service
  };

  const fetchCurrentUser = async () => {
    const { data: { user }, error } = await supabase.auth.getUser();
    if (error) {
      logError("Error fetching current user:", error);
      setError("Unable to fetch user information");
    } else {
      setCurrentUser(user as User);
      fetchUserBalance(user?.id ?? '');
    }
  };

  const fetchUserBalance = async (userId: string) => {
    const { data, error } = await supabase
      .from('user_financials')
      .select('cash_available')
      .eq('user_id', userId)
      .single();

    if (error) {
      logError("Error fetching user balance:", error);
    } else {
      setUserBalance(data.cash_available);
    }
  };

  const fetchRestaurantDetails = async () => {
    const { data, error } = await supabase
      .from("businesses")
      .select("*")
      .eq("id", restaurantId)
      .single();

    if (error) {
      logError("Error fetching restaurant details:", error);
    } else {
      setRestaurant(data);
    }
  };

  const fetchOwners = async () => {
    const { data, error } = await supabase.rpc("get_restaurant_owners", {
      restaurant_id: restaurantId,
    });

    if (error) {
      logError("Error fetching owners:", error);
    } else {
      setOwners(data);
    }
  };

  const handlePurchase = async () => {
    setError(null);
    setSuccess(null);

    if (!currentUser) {
      setError("User not authenticated");
      return;
    }

    const { data, error } = await supabase.rpc("purchase_restaurant_shares", {
      restaurant_id: restaurantId,
      shares_to_buy: sharesToBuy,
      input_user_id: currentUser.id
    });

    if (error) {
      setError(error.message);
    } else if (data && data.success) {
      setSuccess(`Successfully purchased ${sharesToBuy} shares!`);
      fetchOwners();
      fetchUserBalance(currentUser.id);
      setReloadData(true);
      setSharesToBuy(0);
    } else if (data && !data.success) {
      setError(data.message || "Failed to purchase shares");
    }
  };

  const calculateBuyingPrice = () => {
    if (!restaurant) return 0;
    return (sharesToBuy / 100) * restaurant.valuation;
  };

  const maxAffordableShares = () => {
    if (!restaurant || restaurant.valuation === 0) return 0;
    return (userBalance / restaurant.valuation) * 100;
  };

  return (
    <IonModal
      ref={modal}
      isOpen={isOpen}
      onDidDismiss={onClose}
      breakpoints={[0, 0.25, 0.5, 0.75, 1]}
      initialBreakpoint={0.65}
      className="restaurant-modal"
    >

      <IonContent className="ion-padding !glassmorphism-content">
        {!restaurant || !currentUser ? (
          <p>Loading...</p>
        ) : (
          <div className="space-y-4 glassmorphism-content mt-2">
            <section className="flex flex-col gap-4 animate-in fade-in duration-300"><div className="bg-white rounded-3xl p-4 gap-3 flex flex-col w-full h-fit">
              <div className="flex flex-col gap-1">
                <div className="flex flex-row w-full justify-between items-center">
                  <div className="flex flex-row items-center gap-2">
                    <button onClick={onClose} data-shown="true" className="justify-center items-center place-self-start w-6 h-10 cursor-pointer hidden data-[shown=true]:flex"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="min-w-8 h-8 stroke-[1.5]"><polyline points="15 18 9 12 15 6"></polyline></svg></button>
                    <h1 className="text-3xl font-display font-semibold lowercase">{restaurant.name}</h1>
                    </div>
                    
                    </div>
                    <p className="text-gray-600">{restaurant.category}</p>
                    <p className="text-gray-600">{restaurant.business_type}</p>
                    <p className="text-lg font-semibold">Business Value: ${restaurant.valuation && restaurant.valuation.toLocaleString()}</p>
                    <p className="text-lg font-semibold text-green-500">Your Balance: ${userBalance.toLocaleString()}</p>
                    </div>
                    </div>
              </section>
           

            {/* Current Owners section */}
            <div className="bg-white rounded-3xl p-4">
              <h3 className="text-xl font-semibold mb-2">Current Owners</h3>
              {owners.map((owner) => (
                <div key={owner.id} className="mb-2 flex flex-row justify-between">
                  <h4>{owner.username}</h4>
                  <p>{owner.percentage.toFixed(2)}% ownership</p>
                </div>
              ))}
            </div>

            {/* Purchase Shares section */}
            <div className="bg-white rounded-3xl p-4">
              <h3 className="text-xl font-semibold mb-2">Purchase Shares</h3>
              <IonRange
                min={0}
                max={maxAffordableShares()}
                step={0.01}
                mode="ios"
                value={sharesToBuy}
                onIonChange={(e) => setSharesToBuy(e.detail.value as number)}
              >
                <IonLabel slot="start">0%</IonLabel>
                <IonLabel slot="end">{maxAffordableShares().toFixed(2)}%</IonLabel>
              </IonRange>
              <p className="mt-2">Shares to buy: {sharesToBuy.toFixed(2)}%</p>
              <p className="mt-2">Buying price: ${calculateBuyingPrice().toLocaleString()}</p>
              <IonButton
                expand="block"
                onClick={handlePurchase}
                disabled={sharesToBuy <= 0}
                color="primary"
              >
                Purchase Shares
              </IonButton>
            </div>

            {error && <p className="text-red-500">{error}</p>}
            {success && <p className="text-green-500">{success}</p>}
          </div>
        )}
      </IonContent>
    </IonModal>
  );
};

export default RestaurantDetailsModal;
