import React, { FC, ReactNode, useState, useEffect } from 'react';
import { Capacitor } from '@capacitor/core';

// Custom hook to determine the top padding
export const useStatusBarPadding = () => {
  const [paddingTop, setPaddingTop] = useState(0);

  useEffect(() => {
    const adjustPaddingForPlatform = () => {
      const platform = Capacitor.getPlatform();
      
      if (platform === 'ios') {
        // Add padding for iOS devices
        setPaddingTop(44); // Adjust this value for the iOS status bar
      } else {
        setPaddingTop(0); // No padding needed for Android and Web
      }
    };

    adjustPaddingForPlatform();
  }, []);

  return paddingTop;
};

// StatusBarWrapper component with React.FC and children typing
interface StatusBarWrapperProps {
    children: ReactNode;
}

const StatusBarWrapper: FC<StatusBarWrapperProps> = ({ children }: StatusBarWrapperProps) => {
    const paddingTop = useStatusBarPadding();
    return (
        <div className='safe-area-padding' style={{ paddingTop: `${paddingTop}px !important`}}>
            {children}
        </div>
    );
};

export default StatusBarWrapper;
