import React, { useEffect, useState } from 'react';
import { Redirect, Route, RouteProps, RouteComponentProps } from 'react-router-dom';
import {
  IonApp, IonRouterOutlet, setupIonicReact, IonSpinner,
  IonPage,
  IonContent
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Map from './pages/Map';
import Auth from './components/Auth';
import { AuthProvider, useAuth } from './AuthContext';
import { CapacitorUpdater } from '@capgo/capacitor-updater'
import { SplashScreen } from '@capacitor/splash-screen'
import { App as CapacitorApp } from '@capacitor/app'
import { APP_VERSION } from './version';
import { Capacitor } from '@capacitor/core';
import LandingPage from './pages/LandingPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import UsageTerms from './pages/UsageTerms';

setupIonicReact();

interface ProtectedRouteProps extends Omit<RouteProps, 'component'> {
  component: React.ComponentType<RouteComponentProps<any>>;
}

const UpdateLoadingScreen: React.FC<{ progress: number }> = ({ progress }) => (
  <IonPage>
    <IonContent className="ion-padding">
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100%' 
      }}>
        <h2>Updating App</h2>
        <IonSpinner name="circular" />
        <p>Download progress: {progress.toFixed(2)}%</p>
      </div>
    </IonContent>
  </IonPage>
);

const AppContent: React.FC<{ isNative: boolean }> = ({ isNative }) => {
  return (
    <IonApp>
      <AuthProvider>
        <IonReactRouter>
          <IonRouterOutlet>
            {isNative ? (
              // Routes for iOS and Android
              <>
                <Route path="/auth" component={AuthRoute} exact />
                <ProtectedRoute path="/map" component={Map} exact />
                <Route exact path="/" render={() => <Redirect to="/map" />} />
              </>
            ) : (
              // Routes for web
              <>
                <Route path="/" component={LandingPage} exact />
                <Route path="/privacy" component={PrivacyPolicy} exact />
                <Route path="/terms" component={UsageTerms} exact />
                <Route path="/auth" component={AuthRoute} exact />
                <ProtectedRoute path="/map" component={Map} exact />
              </>
            )}
          </IonRouterOutlet>
        </IonReactRouter>
      </AuthProvider>
    </IonApp>
  );
};

const App: React.FC = () => {
  const isNative = Capacitor.isNativePlatform();

  return <AppContent isNative={isNative} />;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, ...rest }) => {
  const { session, loading } = useAuth();

  if (loading) return <IonSpinner />;

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) =>
        session ? <Component {...props} /> : <Redirect to="/auth" />
      }
    />
  );
};

const AuthRoute: React.FC = () => {
  const { session, loading } = useAuth();

  if (loading) return <IonSpinner />;
  return session ? <Redirect to="/map" /> : <Auth />;
};

export default App;