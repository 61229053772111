import React from 'react';
import { IonContent, IonPage } from '@ionic/react';

const PrivacyPolicy: React.FC = () => {
  return (
    <IonPage>
      <IonContent className="ion-padding">
        <h1 className="text-2xl font-bold mb-4">Yes Chef Privacy Policy</h1>
        
        <p className="mb-4">Last updated: September 16, 2024</p>
        
        <h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
        <p className="mb-4">Welcome to Yes Chef, the ultimate restaurant simulation game. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application.</p>
        
        <h2 className="text-xl font-semibold mb-2">2. Information We Collect</h2>
        <ul className="list-disc pl-6 mb-4">
          <li>Personal Information: name, email address, and payment information</li>
          <li>Game Data: in-game progress, purchases, and achievements</li>
          <li>Device Information: IP address, device type, and operating system</li>
          <li>Location Data: general location for regional features and offers</li>
        </ul>
        
        <h2 className="text-xl font-semibold mb-2">3. How We Use Your Information</h2>
        <ul className="list-disc pl-6 mb-4">
          <li>To provide and maintain our Service</li>
          <li>To process your transactions</li>
          <li>To improve game features and user experience</li>
          <li>To send you updates, promotions, and newsletters</li>
        </ul>
        
        <h2 className="text-xl font-semibold mb-2">4. Sharing of Your Information</h2>
        <p className="mb-4">We may share your information with:</p>
        <ul className="list-disc pl-6 mb-4">
          <li>Service providers who assist in game operations</li>
          <li>Advertising partners for targeted in-game promotions</li>
          <li>Legal authorities when required by law</li>
        </ul>
        
        <h2 className="text-xl font-semibold mb-2">5. Data Security</h2>
        <p className="mb-4">We implement security measures to protect your personal information. However, no method of transmission over the Internet is 100% secure.</p>
        
        <h2 className="text-xl font-semibold mb-2">6. Your Rights</h2>
        <p className="mb-4">You have the right to access, correct, or delete your personal information. Contact us at privacy@yeschef.com for assistance.</p>
        
        <h2 className="text-xl font-semibold mb-2">7. Children&apos;s Privacy</h2>
        <p className="mb-4">Our Service is not intended for children under 13. We do not knowingly collect personal information from children under 13.</p>
        
        <h2 className="text-xl font-semibold mb-2">8. Changes to This Privacy Policy</h2>
        <p className="mb-4">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        
        <h2 className="text-xl font-semibold mb-2">9. Contact Us</h2>
        <p className="mb-4">If you have any questions about this Privacy Policy, please contact us at:</p>
        <p>Email: privacy@yeschef.com</p>
        
      </IonContent>
    </IonPage>
  );
};

export default PrivacyPolicy;