import React from "react";
import "./Tab1.css";
import MapComponent from "../components/MapComponent";

const Map: React.FC = () => {

  return ( 
    <>
        <MapComponent />
    </>
  );
};

export default Map;
