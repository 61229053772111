import React, { useRef, useState, useEffect } from "react";
import {
  IonContent,
  IonModal,
  IonSearchbar,
  IonList,
  IonItem,
  IonLabel,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/react";
import { Geolocation } from '@capacitor/geolocation';
import { supabase } from "../supabaseClient";
import RestaurantDetailsModal from "./RestaurantDetailsModal";
import './SearchBusiness.css';
import { set } from "lodash";

interface Business {
  id: string;
  name: string;
  category: string;
  business_type: string;
  distance: number;
  address: string;
}

export const SearchBusiness: React.FC<{ setReloadData: React.Dispatch<React.SetStateAction<boolean>>, reloadData: boolean }> = ({ setReloadData, reloadData }) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [filteredBusinesses, setFilteredBusinesses] = useState<Business[]>([]);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [selectedBusinessId, setSelectedBusinessId] = useState<string | null>(null);
  const [isRestaurantModalOpen, setIsRestaurantModalOpen] = useState(false);

  const loadBusinesses = async (isInitial = false) => {
    try {
      const position = await Geolocation.getCurrentPosition();
      const { latitude, longitude } = position.coords;

      const { data, error } = await supabase.rpc('search_nearby_businesses', {
        user_lat: latitude,
        user_lon: longitude,
        search_radius: 250000000,
        business_type_parameter: null,
        page: isInitial ? 1 : page,
        page_size: 25
      });

      if (error) throw error;

      const filteredData = searchTerm
        ? data.filter((b: Business) => b.name.toLowerCase().includes(searchTerm.toLowerCase()))
        : data;

      if (isInitial) {
        setBusinesses(filteredData);
        setFilteredBusinesses(filteredData);
        setPage(2);
      } else {
        setBusinesses(prevBusinesses => [...prevBusinesses, ...filteredData]);
        setFilteredBusinesses(prevFiltered => [...prevFiltered, ...filteredData]);
        setPage(prevPage => prevPage + 1);
      }

      setInfiniteDisabled(filteredData.length < 25);

    } catch (error) {
      // Replace console.error with a custom error logging function
      logError('Error loading businesses:', error);
    }
  };

  useEffect(() => {
    loadBusinesses(true);
  }, []); // Empty dependency array for initial load

  useEffect(() => {
    const filtered = businesses.filter(business =>
      business.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredBusinesses(filtered);
    setInfiniteDisabled(filtered.length < businesses.length);
  }, [searchTerm, businesses]);

  const handleSearch = (e: CustomEvent) => {
    setSearchTerm(e.detail.value ?? '');
  };

  const loadMore = async (ev: CustomEvent<void>) => {
    await loadBusinesses(false);
    (ev.target as HTMLIonInfiniteScrollElement).complete();
  };

  const openRestaurantDetails = (businessId: string) => {
    setSelectedBusinessId(businessId);
    setIsRestaurantModalOpen(true);
    modal.current?.dismiss();
  };

  const closeRestaurantDetails = () => {
    setIsRestaurantModalOpen(false);
    setSelectedBusinessId(null);
  };

  return (
    <>
      <IonModal
        ref={modal}
        trigger="open-modal-search-business"
        initialBreakpoint={0.75}
        breakpoints={[0.25, 0.5, 0.75, 1]}
        backdropDismiss={true}
        className="search-business-modal"
      >
        <IonContent className="ion-padding !bg-transparent">
          <div className="rounded-full p-2 mb-0 flex items-center">
            <IonSearchbar
              onClick={() => modal.current?.setCurrentBreakpoint(1)}
              placeholder="Search"
              mode="ios"
              value={searchTerm}
              onIonChange={handleSearch}
              className="custom-searchbar"
            />
          </div>
          <IonList className="!bg-white !rounded-lg" inset={true} lines="inset">
            {filteredBusinesses.map((business) => (
              <IonItem 
                key={business.id} 
                className="!border-b !border-gray-200 rounded-none"
                onClick={() => openRestaurantDetails(business.id)}
              >
                <IonLabel>{business.name}</IonLabel>
              </IonItem>
            ))}
          </IonList>
          <IonInfiniteScroll
            onIonInfinite={loadMore}
            threshold="100px"
            disabled={isInfiniteDisabled}
          >
            <IonInfiniteScrollContent
              loadingSpinner="bubbles"
              loadingText="Loading more businesses..."
            />
          </IonInfiniteScroll>
        </IonContent>
      </IonModal>
      <RestaurantDetailsModal
        isOpen={isRestaurantModalOpen}
        onClose={closeRestaurantDetails}
        restaurantId={selectedBusinessId}
        setReloadData={setReloadData}
        reloadData={reloadData}
      />
    </>
  );
};

// Add this function outside of the component
const logError = (message: string, error: unknown) => {
  // Implement your preferred error logging method here
  // For example, you could send errors to a monitoring service
};