import React from 'react';
import { IonContent, IonPage } from '@ionic/react';

const UsageTerms: React.FC = () => {
  return (
    <IonPage>
      <IonContent className="ion-padding">
        <h1 className="text-2xl font-bold mb-4">Yes Chef Usage Terms</h1>
        
        <p className="mb-4">Last Updated: September 16, 2024</p>
        
        <h2 className="text-xl font-semibold mb-2">1. Acceptance of Terms</h2>
        <p className="mb-4">By accessing or using Yes Chef (the &quot;Service&quot;), you agree to be bound by these Terms of Use. If you disagree with any part of the terms, you may not access the Service.</p>
        
        <h2 className="text-xl font-semibold mb-2">2. Definitions</h2>
        <ul className="list-disc pl-6 mb-4">
          <li>&quot;User,&quot; &quot;you,&quot; and &quot;your&quot; refer to the person, company, or organization that has visited or is using the Service.</li>
          <li>&quot;We,&quot; &quot;us,&quot; and &quot;our&quot; refer to Yes Chef Inc., the company that owns and operates the Service.</li>
          <li>&quot;Content&quot; refers to all information, data, text, software, music, sound, photographs, graphics, video, messages, or other materials, whether publicly posted or privately transmitted.</li>
        </ul>
        
        <h2 className="text-xl font-semibold mb-2">3. User Accounts</h2>
        <p className="mb-4">To access certain features of the Service, you must register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>
        
        <h2 className="text-xl font-semibold mb-2">4. Virtual Currency and Property</h2>
        <p className="mb-4">The Service may include a virtual, in-game currency (&quot;Virtual Currency&quot;) and virtual in-game items or services (&quot;Virtual Property&quot;). You acknowledge that:</p>
        <ul className="list-disc pl-6 mb-4">
          <li>Virtual Currency and Virtual Property have no real-world value and cannot be redeemed for real money, goods, or services.</li>
          <li>We reserve the right to manage, control, modify, or eliminate Virtual Currency and Virtual Property at our sole discretion.</li>
          <li>The purchase of Virtual Currency or Virtual Property is final and non-refundable.</li>
        </ul>
        
        <h2 className="text-xl font-semibold mb-2">5. Intellectual Property Rights</h2>
        <p className="mb-4">The Service and its original content, features, and functionality are and will remain the exclusive property of Yes Chef Inc. and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries.</p>
        
        <h2 className="text-xl font-semibold mb-2">6. User-Generated Content</h2>
        <p className="mb-4">You retain any and all of your rights to any Content you submit, post, or display on or through the Service and you are responsible for protecting those rights. You grant us a license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service.</p>
        
        <h2 className="text-xl font-semibold mb-2">7. Prohibited Activities</h2>
        <p className="mb-4">You agree not to engage in any of the following prohibited activities:</p>
        <ul className="list-disc pl-6 mb-4">
          <li>Copying, distributing, or disclosing any part of the Service in any medium, including without limitation by any automated or non-automated &quot;scraping&quot;.</li>
          <li>Using any automated system, including without limitation &quot;robots,&quot; &quot;spiders,&quot; &quot;offline readers,&quot; etc., to access the Service.</li>
          <li>Transmitting spam, chain letters, or other unsolicited email.</li>
          <li>Attempting to interfere with, compromise the system integrity or security, or decipher any transmissions to or from the servers running the Service.</li>
          <li>Taking any action that imposes, or may impose at our sole discretion an unreasonable or disproportionately large load on our infrastructure.</li>
        </ul>
        
        <h2 className="text-xl font-semibold mb-2">8. Termination</h2>
        <p className="mb-4">We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</p>
        
        <h2 className="text-xl font-semibold mb-2">9. Limitation of Liability</h2>
        <p className="mb-4">In no event shall Yes Chef Inc., nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.</p>
        
        <h2 className="text-xl font-semibold mb-2">10. Governing Law</h2>
        <p className="mb-4">These Terms shall be governed and construed in accordance with the laws of [Your State/Country], without regard to its conflict of law provisions.</p>
        
        <h2 className="text-xl font-semibold mb-2">11. Changes to Terms</h2>
        <p className="mb-4">We reserve the right, at our sole discretion, to modify or replace these Terms at any time. By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms.</p>
        
        <h2 className="text-xl font-semibold mb-2">12. Contact Us</h2>
        <p className="mb-4">If you have any questions about these Terms, please contact us at:</p>
        <p>Email: legal@yeschef.com</p>
      </IonContent>
    </IonPage>
  );
};

export default UsageTerms;